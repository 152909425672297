/* Theme CSS */
.scale-hover {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(0px) scale(0.1, 1);
    opacity: 0;
    z-index: 1;
    transition: transform 0.3s, opacity 0.3s;
  }

  &:hover::before {
    opacity: 1;
    transform-origin: 50%;
    transform: scale(1);
  }

  &:active::before {
    opacity: 0;
  }
}

// overrides
.card_no-border {
  border: none;
}

.modal-dialog {
  @media screen and (min-width: 577px) {
    min-width: 90%;
  }

  .editable-table:last-child {
    margin-bottom: 2rem;
  }
}

.modal-header {
  border-bottom: none;
  padding: 1.143rem;
}

.modal-body {
  padding: 0 1.143rem;

  .form-label,
  th {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
  }

  .form-group {
    input,
    select {
      border: 2px solid #e9edf3;
    }
  }
}

.modal-footer {
  border-top: none;
  justify-content: flex-start;
}

.modal-title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
}

.modal-title.h4 {
  font-size: 1.25rem;
}

.btn {
  width: 200px;
}

.form-label {
  font-size: 11px;
}
