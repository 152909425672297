.editable-table {
  .react-bootstrap-table {
    border-bottom: 1px solid #e9edf3;

    table {
      border: none;
      margin-bottom: 0;

      .delete-cell {
        max-width: 50px;
      }
    }

    thead {
      tr {
        height: 40px !important;
        border: none;
      }

      background-color: #fafafb;
    }

    tbody {
      tr {
        height: 50px !important;

        td {
          position: relative;

          input,
          textarea {
            width: 100%;
            height: 50px;
          }
        }
      }
    }

    th,
    tr,
    td {
      border: none;
      vertical-align: middle;
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #e9edf3;
    }
  }
}
